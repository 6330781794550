var mod = {
	init: function () {

    $(document).ready(function() {
        var stickyNav = function(){
          var scrollTop = $(window).scrollTop();

          if($('.cookies').length){
            var vHeight = $('.cookies').innerHeight();
            $('body').css('padding-top',vHeight);
            //$('body #slider #owl-demo .item img').css('height','calc(100vh - '+vHeight+'px)');
            $('body #slider #owl-demo .item').css('height','calc(100vh - '+vHeight+'px)');

          }else{
            $('body').removeAttr('style');
            $('body #slider #owl-demo .item img').removeAttr('style');
          }
          
			    if (scrollTop > 20) { 
			        $('body').addClass('sticky');
              $('.header.pagesimple').removeClass('hideLogo'); 
			    } else {
			        $('body').removeClass('sticky'); 
              $('.header.pagesimple').addClass('hideLogo'); 
			    }
			};

      stickyNav();
      
			$(window).scroll(function() {
				stickyNav();
      });

      var popWidth = '90%';
      var popMaxWidth = '1024px';
      
      $('.wysiwyg_content a[target="_blank"], footer a[target="_blank"]').click(function(e){
        e.preventDefault();

        var vLink = $(this).attr('href');
        
        if($(window).innerWidth() <= 700){
          popWidth = '100%';
          popMaxWidth = '100%';
        }else{
          popWidth = '90%';
          popMaxWidth = '1024px';
        }

        var htmlPopIn = "<div class=\"popIn\">";
            htmlPopIn += "<h2 class=\"title\">You are now leaving the <strong>EverydayWins</strong> website supported by Ipsen</h2>";
            htmlPopIn += "<div class=\"desc\"><p>Links to sites outside of this website are provided as a resource to the viewer. Ipsen accepts no responsibility for the content of linked sites.</p></div>";
            htmlPopIn += "<div class=\"cmd\">";
            htmlPopIn += "<ul>";
            htmlPopIn += "<li><a href=\"#\" class=\"prev\">Cancel</a></li>";
            htmlPopIn += "<li><a href=\""+ vLink +"\" class=\"next\" target=\"_blank\">Continue</a></li>";
            htmlPopIn += "</ul>";
            htmlPopIn += "</div>";
            htmlPopIn += "</div>";
        
        $.colorbox({
          html: htmlPopIn, 
          overlayClose : false, 
          width : popWidth,
          maxWidth : popMaxWidth,
          onComplete : function(){
            $('.popIn .prev').click(function(e){
              e.preventDefault();
              $.colorbox.close();
            });
            $('.popIn .next').click(function(e){
              $.colorbox.close();
            });
          }
        });

      });

      $(window).resize(function() {
        if($(window).innerWidth() <= 700){
          popWidth = '100%';
          popMaxWidth = '100%';
        }else{
          popWidth = '90%';
          popMaxWidth = '1024px';
        }
          jQuery.colorbox.resize({width:popWidth,maxWidth : popMaxWidth});
      });

		});

    $('.btnMore .seeMore').click(function(e){
      e.preventDefault();
      $(this).toggleClass('open');
      $(this).parents('.list').toggleClass('listOpen');
    });

     $('.scdown a').click(function(e){
      e.preventDefault();
      $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top}, 1000, 'linear');
    });

    if($('.dispatch').length){

      $('.dispatch .title').click(function(){
        $(this).toggleClass('openTitle');
        $(this).next('.list').toggleClass('openListAccord');
        $(this).siblings('.list').not($(this).next('.list')).removeClass('openListAccord');
        $(this).siblings('.title').removeClass('openTitle');
      });
    }

    $('.menuRight').clone().appendTo('.content_menu');

     if (window.innerWidth > 1025) {
        $('.bxslider').bxSlider({
          mode: 'vertical',
          speed: 500,
          slideMargin:30,
          infiniteLoop: false,
          pager: false, 
          hideControlOnEnd:true,
          controls: true, minSlides: 4, moveSlides: 1, adaptiveHeight: false
        });
     }
    if (window.innerWidth < 768) {
        $("#living .group").owlCarousel({
          items : 1,
          slideSpeed : 300,
          paginationSpeed : 400
        }); 
      }
    if (window.innerWidth < 1025) {
        $(".squares_more.bxslider").removeClass('bxslider');
        $(".squares_more").owlCarousel({
          margin: 30,
          responsiveClass:true,
          responsive:{
              0:{
                items:1,
                margin:0
              },
              767:{
                items:3,
                margin:30
                
              },
              1024:{
                items:3,
                margin:30
              }
          }
        }); 
      }


     $('.btns_cookies .btn_ok').click(function (e) {
        e.preventDefault();
        $.cookie("cookie_bar", false, { expires: 1, path: '/' });
        $('.cookies').remove();
        $(window).scroll();
      });
 

		$("#owl-demo").owlCarousel({
 
			navigation : true, // Show next and prev buttons
			slideSpeed : 1500,
			paginationSpeed : 1500,
			items : 1,
      loop:true,
      autoplay:true
	  	});

		// var offset = $("header").offset().top;
  //       $(document).scroll(function(){
  //           var scrollTop = $(document).scrollTop();
  //           if(scrollTop > offset){
  //               $("header").addClass('fixed');
  //           }
  //           else {
  //               $("header").removeClass('fixed');
  //           }
  //       });

  	$('.btns_cookies .btn_ok').click(function(e) {
  		e.preventDefault();
      $('.cookies').remove();
      $(window).scroll();
  	});

  	$('.btnMenu').click(function(e) {
  		$('.content_menu').addClass('open');
  		$('body').css( "overflow-y", "hidden" );
  	});

  	$('.closem').click(function(e) {
  		$('.content_menu').removeClass('open');
  		$('body').css( "overflow-y", "auto" );
  	});

  	$('.global').click(function(e) {
  		$('.countries').addClass('open');
      $('.content_menu').removeClass('open');
  		$('body').css( "overflow-y", "hidden" );
  		 $(window).scrollTop(0);
  	});

  	$('.closel').click(function(e) {
  		$('.countries').removeClass('open');
  		$('body').css( "overflow-y", "auto" );
  	});

  	$('.content_menu .menu li a,#countries li a').click(function(){
	    $('li').removeClass("active");
	    $(this).parent().addClass("active");
	  });
    if (window.innerWidth > 767) {
      $('.r_social .bsocial').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
      });
    }

	}
};

module.exports = mod;
