// polyfills.js
// ============

// Object.create() polyfill
// ------------------------
// Prototypal inheritance FTW.
//
// Usage:
//
//     var animal = Object.create(null);
//     animal.voice = "grr";
//     animal.talk = function() { return this.voice; };
//
//     var cat = Object.create(animal);
//     cat.talk();    // "grr"
//
//     cat.voice = "meow";
//     cat.talk();    // "meow"
if (typeof Object.create !== 'function') {
	Object.create = function(o) {
		function F() {}

		F.prototype = o;

		return new F();
	};
}

// CustomEvent() polyfill
// ----------------------
// Custom events for everybody!
//
// Usage:
//
//     // Create custom event
//     var my_event = new CustomEvent("CustomEvent");
//     my_event.initCustomEvent("foobar", false, false, {});
//
//     // Dispatch custom event
//     window.dispatchEvent(my_event);
//
//     // Listen to custom event
//     window.addEventListener("foobar", my_handler, false);
(function() {
	if (typeof window.CustomEvent === 'function') {
		return;
	}

	function CustomEvent(event, params) {
		params = params || {
			bubbles    : false,
			cancelable : false,
			detail     : undefined
		};

		var evt = document.createEvent('CustomEvent');

		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

		return evt;
	}

	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent    = CustomEvent;
})();

// Breakpoint event
// ----------------
// Breakpoint-triggered custom event.
//
// Usage:
//
//     window.addEventListener("desk-enter", my_handler, false);
(function() {
	if (!window.getComputedStyle) {
		return;
	}

	var debounce = require("./debounce");
	var head     = document.getElementsByTagName('head')[0];
	var currentMediaQuery = "";

	function trigger() {
		var mq = window.getComputedStyle(head)['font-family'];

		if(mq !== currentMediaQuery) {
			currentMediaQuery = mq;

			var event = new CustomEvent('CustomEvent');

			event.initCustomEvent(mq.replace(/['"]+/g, '') + '-enter', false, false, {});

			window.dispatchEvent(event);
		}
	}

	var debbie = debounce(trigger, 100, false);

	window.addEventListener('resize', debbie, false);
	window.addEventListener('load', function(){
		trigger();
	}, false);
})();
