// debounce()
// ==========
// Part of FullSix's micro library.
//
// Underscore's debounce().
//
// Usage:
//
//     var debounce = require("./f6/debounce");
//     var debounced = debounce(my_function, 100, false);
//     window.addEventListener('resize', debounced, false);
module.exports = function(func, wait, immediate) {
	var timeout;

	func();

	return function() {
		var context = this, args = arguments;
		var callNow = immediate && !timeout;

		var later = function() {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};

		window.clearTimeout(timeout);

		timeout = window.setTimeout(later, wait);

		if (callNow) {
			func.apply(context, args);
		}
	};
};
