/*
 * js/modules/globals.js
 *
 * Havas Corporate
 *
 * Expose global libs
 *
 * jQuery
 * owl.carousel"
 * jquery-match-height
 * bxslider
 * sticky-kit
 *
 */

module.exports = function() {
	global.jQuery 	= global.$ = require("jquery");
	global.owlCarousel 	= require("owl.carousel");
	global.matchHeight 	= require("jquery-match-height");
	global.bxslider = require("bxslider");
	global.colorbox = require("jquery-colorbox");
	global.cookie = require("jquery.cookie");
}();
