// ready()
// =======
// Part of FullSix's micro library.
//
// Cross-browser `$(document).ready()` alternative.
//
// Usage:
//
//     var ready = require("./f6/ready");
//     ready(function() {
//        // do something
//     });
module.exports = function ready(func) {
	if (document.readyState === 'interactive'){
		func();
	}
	if (document.addEventListener) {
		document.addEventListener('DOMContentLoaded', func, false);
	} else {
		document.attachEvent('onreadystatechange', function() {
			if (document.readyState === 'interactive') {
				func();
			}
		});
	}
};
