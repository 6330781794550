// isviewable()
// ============
// Part of FullSix's micro library.
//
// Returns `true` if `element` is entirely within the viewport.
//
// Usage:
//
//     var isViewable = require("./f6/isviewable");
//     if (isViewable(my_element)) {
//         console.log("Gotcha!");
//     }
module.exports = function(element) {
	var boundRect = element.getBoundingClientRect();

	return boundRect.top    > 0 &&
	       boundRect.right  > 0 &&
	       boundRect.bottom > 0 &&
	       boundRect.top  + element.offsetHeight < (window.innerHeight || document.documentElement.clientHeight) &&
	       boundRect.left + element.offsetWidth  < (window.innerWidth  || document.documentElement.clientWidth);
};
