// svg()
// ============
// Part of FullSix's micro library.
//
// Returns a proper empty svg element.
//
// Usage:
//
//     var mySVGElement = require("./f6/svg")(200, 400);
module.exports = function(width, height){
	width  = width  || 20;
	height = height || 20;

	var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

	svg.setAttribute("version", "1.1");
	svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
	svg.setAttribute("xmlns:a", "http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/");
	svg.setAttribute("x", "0px");
	svg.setAttribute("y", "0px");
	svg.setAttribute("width", width + "px");
	svg.setAttribute("height", height + "px");
	svg.setAttribute("viewBox", "0 0 " + width + " " + height);
	svg.setAttribute("enable-background", "new 0 0 " + width + " " + height);
	svg.setAttribute("xml:space", "preserve");

	return svg;
};
