// pad()
// =====
// Part of FullSix's micro library.
//
// Returns a left-padded string representation of the given number.
//
// Usage:
//
//     var pad = require("./f6/pad");
//     console.log(pad(12, 4678));    // 0012
module.exports = function(value, limit) {
	return value < limit ? limit.toString().replace(/\d/g, '0').slice(Math.ceil(value / 10) + 1) + value : value;
};
