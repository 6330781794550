// chunkify()
// ==========
// Part of FullSix's micro library.
//
// Returns an array containing as many chunks of the given size from the given array.
//
// Usage:
//
//     var chunkify = require("./f6/chunkify");
//     var my_array = [1, 2, 3, 4, 5, 6, 7];
//     console.log(chunkify(my_array, 4));    // [[1, 2, 3, 4], [5, 6, 7]]
module.exports = function(list, length) {
	var chunks = [];
	var index  = 0;
	var limit  = list.length;

	while (index < limit) {
		chunks.push(list.slice(index, index += length));
	}

	return chunks;
};
