// capitalize()
// ============
// Part of FullSix's micro library.
//
// Returns the given string with the first character uppercased.
//
// Usage:
//
//     var capitalize = require("./f6/capitalize");
//     console.log(capitalize("foobar"));    // Foobar
module.exports = function(txt){
	return txt.replace(/^[a-zA-Z]/, function(match){
		return match.toUpperCase();
	});
};
