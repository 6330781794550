// haversine()
// ============
// Part of FullSix's micro library.
//
// Returns the distance between two geolocation points.
//
// Usage:
//
//     var haversine = require("./f6/haversine");
//     var one = {
//         lat: 55.45,
//         long: 37.37
//     }
//     var two = {
//         lat: 39.110,
//         long: 125.4417
//     }
//     console.log(haversine.km(one.lat, one.long, two.lat, two.long));
var equatorialEarthRadius = 6378.1370;
var degreeInRadiant       = Math.PI / 180.0;

module.exports = {
	km: function (lat1, long1, lat2, long2) {
		var dlong = (long2 - long1) * degreeInRadiant;
		var dlat = (lat2 - lat1) * degreeInRadiant;

		var a = Math.pow(Math.sin(dlat / 2.0), 2.0) + Math.cos(lat1 * degreeInRadiant) * Math.cos(lat2 * degreeInRadiant) * Math.pow(Math.sin(dlong / 2.0), 2.0);
		var c = 2.0 * Math.atan2(Math.sqrt(a), Math.sqrt(1.0 - a));
		var d = equatorialEarthRadius * c;

		return d;
	},
	m: function(lat1, long1, lat2, long2) {
		return 1000.0 * this.km(lat1, long1, lat2, long2);
	}
};
