// main.js
// =======

// var esmod = require('./js/modules/es6mod').esmod;

// var mod = require('./js/modules/mod');

// mod.init();
// esmod();

// main.js
// =======
// The project's entry point.
(function main() {
	// Globals setup
	require("./js/modules/globals");


	var ready			= require("./js/f6/ready");
	var mod = require('./js/modules/mod');

	// `go()` is executed when the DOM is ready
	var go = function(){
		console.log("ok");
		mod.init();
	};

	ready(go);
})();